// If you want to override variables do it here
@import "variables";

$enable-ltr: true;
$enable-rtl: true;

// Import CoreUI for React components library
@import "@coreui/coreui/scss/coreui";

// Import Chart.js custom tooltips styles
@import "@coreui/chartjs/scss/coreui-chartjs";

@import "layout";
@import "example";

// If you want to add custom CSS you can put it here.
@import "custom";

.App-header {
  .wrapper {
    padding-left: 0 !important;
  }
}

.searchbar {
  width: 18rem;
  padding: 0.3rem;
  border: none;
  border-radius: 100rem;
  padding-top: 0.5rem;
  margin: 0.5rem;
  box-shadow: none;
  outline: none;
  box-shadow: 0 3px 10px rgb(0 0 0 / 50%);
}

.forgot {
  justify-content: end;
}

.cards {
  background-color: #111118;
  width: 275px;
  text-align: center;
  color: #000000;
  align-self: center;
  justify-content: center;
  align-items: center;
}

.cards {
  .card-icons {
    height: 80px;
  }
}

.modal {
  margin: 0.6rem;
  padding: 0.5rem 1.5rem;
  color: #ffffff;
  font-weight: bold;
  font-size: 0.7rem;
}

.modal-btn {
  margin: 0.6rem;
  padding: 0.5rem 1.5rem;
  background-color: #111118;
  color: #ffffff;
  font-weight: bold;
  font-size: 0.7rem;
}

.login-text {
  color: linear-gradient(271.86deg, #ff8126 0.13%, #fda24e 97.84%);
}

.update-btn {
  height: 40px;
  width: 80px;
}

.pagination {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 2rem 0;
}

.text-center {
  .admin-image {
    width: 150px;
    height: 100px;
    // margin-bottom: 1rem;
  }
}

.admin-details {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.forgot {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 1rem;
}

.sidebar-nav .nav-link.active {
  background: linear-gradient(271.86deg, #ff8126 0.13%, #fda24e 97.84%);
  border-radius: 10px;
}

.btn-send {
  background: linear-gradient(
    271.86deg,
    #ff8126 0.13%,
    #fda24e 97.84%
  ) !important;
  box-shadow: 0px 23px 24px -10px rgba(255, 123, 57, 0.37) !important;
  border-radius: 11px !important;
  color: white !important;
  font-size: 16px !important;
  padding: 8px 25px !important;
  font-weight: 700 !important;
}

.btn-send:hover {
  background: white !important;
  border: 1px solid #ff8126 !important;
  color: #ff8126 !important;
}

.btn-send.line-height-manage:hover {
  line-height: initial !important;
}

.copied-text h6 {
  color: black;
  font-weight: 700;
  margin-bottom: 10px;
}

.copied-text .form-control {
  padding-top: 8px;
  padding-block: 8px;
}

.copied-text .btn-copied {
  background: linear-gradient(271.86deg, #ff8126 0.13%, #fda24e 97.84%);
  // background: #33ba95;
  border-radius: 8px;
  color: white;
  font-size: 16px;
  padding-top: 10px;
  // padding-block: 8px;
  font-weight: 700;
}

.copied-text .btn-copied:hover {
  background: white;
  color: #ff8126;
  border-color: #ff8126;
}
